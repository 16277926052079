body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

.App {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.App-link {
  color: #61dafb;
}

.home-links {
  display: flex;
  justify-content: space-evenly;
}

.list-view {
  max-width: 900px;
  margin: 0 auto;
}

.list-view-header {
  display: flex;
  flex-direction: row;
  margin: 8px;
}

.list-view-header-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list-view-item-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 8px;
}

.list-view-item-header-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 8px;
}

.MuiTypography-root.list-view-house-id {
  margin-right: 8px;
  color: #3573EA;
}

.days-open-dots {
  display: flex;
  justify-content: space-evenly;
}

.day-open-dot {
  height: 24px;
  width: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid darkgray;
  color: darkgray;
}

.is-open {
  background-color: #3573EA;
  border: 1px solid black;
  color: white;
}

.payment-items {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.payment-item {
  width: 1.5em;
}

.map-view {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.disabled {
  filter: grayscale(100%);
}

.square-logo.disabled {
  opacity: 0.5;
}

.rummage-location-title {
  text-align: center;
}

/* Google Maps overrides */
div.gm-style-iw-d {
  overflow: hidden !important;
}

div.gm-style-iw,div.gm-style-iw-c {
  padding: 12px !important;
}